.cal-month-view .cal-header {
    text-align: center;
    font-weight: bolder; }
  
  .cal-month-view .cal-cell-row:hover {
    background-color: #fafafa; }
  
  .cal-month-view .cal-header .cal-cell {
    padding: 5px 0;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    display: block;
    white-space: nowrap; }
  
  .cal-month-view .cal-cell-row .cal-cell:hover,
  .cal-month-view .cal-cell.cal-has-events.cal-open {
    background-color: #ededed; }
  
  .cal-month-view .cal-days {
    border: 1px solid #e1e1e1;
    border-bottom: 0; }
  
  .cal-month-view .cal-cell-top {
    min-height: 78px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1; }
  
  .cal-month-view .cal-cell-row {
    display: -webkit-box;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex; }
  
  .cal-month-view .cal-cell {
    float: left;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch; }
  
  .cal-month-view .cal-day-cell {
    min-height: 100px; }
    @media all and (-ms-high-contrast: none) {
      .cal-month-view .cal-day-cell {
        display: block; } }
  
  .cal-month-view .cal-day-cell:not(:last-child) {
    border-right: 1px solid #e1e1e1; }
  
  .cal-month-view .cal-days .cal-cell-row {
    border-bottom: 1px solid #e1e1e1; }
  
  .cal-month-view .cal-day-badge {
    margin-top: 18px;
    margin-left: 10px;
    background-color: #b94a48;
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: white;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 10px; }
  
  .cal-month-view .cal-day-number {
    font-size: 1.2em;
    font-weight: 400;
    opacity: 0.5;
    margin-top: 15px;
    margin-right: 15px;
    float: right;
    margin-bottom: 10px; }
  
  .cal-month-view .cal-events {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    margin: 3px;
    line-height: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
  
  .cal-month-view .cal-event {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin: 2px;
    border-color: #d1e8ff;
    color: #fff; }
  
  .cal-month-view .cal-day-cell.cal-in-month.cal-has-events {
    cursor: pointer; }
  
  .cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
    opacity: 0.1;
    cursor: default; }
  
  .cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
    color: darkred; }
  
  .cal-month-view .cal-day-cell.cal-today {
    background-color: #e8fde7; }
  
  .cal-month-view .cal-day-cell.cal-today .cal-day-number {
    font-size: 1.9em; }
  
  .cal-month-view .cal-day-cell.cal-drag-over {
    background-color: #e0e0e0 !important; }
  
  .cal-month-view .cal-open-day-events {
    padding: 15px;
    color: white;
    background-color: #555;
    -webkit-box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);
            box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5); }
  
  .cal-month-view .cal-open-day-events .cal-event {
    position: relative;
    top: 2px; }
  
  .cal-month-view .cal-out-month .cal-day-badge,
  .cal-month-view .cal-out-month .cal-event {
    opacity: 0.3; }
  
  .cal-month-view .cal-draggable {
    cursor: move; }
  
  .cal-month-view .cal-drag-active * {
    pointer-events: none; }
  
  .cal-month-view .cal-event-title {
    cursor: pointer; }
    .cal-month-view .cal-event-title:hover {
      text-decoration: underline; }
  
  .cal-week-view {
    /* stylelint-disable-next-line selector-type-no-unknown */
  margin: 20px;
  height: 60vh;
  overflow: scroll;
 }
    .cal-week-view .cal-day-headers {
      display: -webkit-box;
      display: -ms-flexbox;
      -js-display: flex;
      display: flex;
      border: 1px solid #e1e1e1;
      padding-left: 70px; }
    .cal-week-view .cal-day-headers .cal-header {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      text-align: center;
      padding: 5px; }
      .cal-week-view .cal-day-headers .cal-header:not(:last-child) {
        border-right: 1px solid #e1e1e1; }
      .cal-week-view .cal-day-headers .cal-header:first-child {
        border-left: 1px solid #e1e1e1; }
    .cal-week-view .cal-day-headers .cal-header:hover,
    .cal-week-view .cal-day-headers .cal-drag-over {
      background-color: #ededed; }
    .cal-week-view .cal-day-headers span {
      font-weight: 400;
      opacity: 0.5; }
    .cal-week-view .cal-day-column {
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      border-left: solid 1px #e1e1e1; }
    .cal-week-view .cal-event {
      font-size: 12px;
      // color: #1e90ff; 
      }
    .cal-week-view .cal-time-label-column {
      width: 70px;
      height: 100%; }
    .cal-week-view .cal-all-day-events {
      border: solid 1px #e1e1e1;
      border-top: 0;
      border-bottom-width: 3px;
      padding-top: 3px;
      position: relative; }
      .cal-week-view .cal-all-day-events .cal-day-columns {
        height: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        position: absolute;
        top: 0;
        z-index: 0; }
      .cal-week-view .cal-all-day-events .cal-events-row {
        position: relative;
        height: 31px;
        margin-left: 70px; }
      .cal-week-view .cal-all-day-events .cal-event-container {
        display: inline-block;
        position: absolute; }
        .cal-week-view .cal-all-day-events .cal-event-container.resize-active {
          z-index: 1;
          pointer-events: none; }
      .cal-week-view .cal-all-day-events .cal-event {
        padding: 0 5px;
        margin-left: 2px;
        margin-right: 2px;
        height: 28px;
        line-height: 28px; }
      .cal-week-view .cal-all-day-events .cal-starts-within-week .cal-event {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px; }
      .cal-week-view .cal-all-day-events .cal-ends-within-week .cal-event {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px; }
      .cal-week-view .cal-all-day-events .cal-time-label-column {
        display: -webkit-box;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        font-size: 14px; }
      .cal-week-view .cal-all-day-events .cal-resize-handle {
        width: 6px;
        height: 100%;
        cursor: col-resize;
        position: absolute;
        top: 0; }
        .cal-week-view .cal-all-day-events .cal-resize-handle.cal-resize-handle-after-end {
          right: 0; }
    .cal-week-view .cal-header.cal-today {
      background-color: #e8fde7; }
    .cal-week-view .cal-header.cal-weekend span {
      color: #8b0000; }
    .cal-week-view .cal-event,
    .cal-week-view .cal-header {
      overflow: hidden;
      -o-text-overflow: ellipsis;
         text-overflow: ellipsis;}
    .cal-week-view .cal-drag-active {
      pointer-events: none;
      z-index: 1; }
      .cal-week-view .cal-drag-active * {
        pointer-events: none; }
    .cal-week-view .cal-time-events {
      position: relative;
      border: solid 1px #e1e1e1;
      border-top: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      -js-display: flex;
      display: flex; }
      .cal-week-view .cal-time-events .cal-day-columns {
        display: -webkit-box;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1; }
        .cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
          background-color: #ededed; }
      .cal-week-view .cal-time-events .cal-day-column {
        position: relative; }
      .cal-week-view .cal-time-events .cal-event-container {
        position: absolute;
        z-index: 1; }
      .cal-week-view .cal-time-events .cal-event {
        width: 100%;
        height: calc(100% - 2px);
        line-height: 25px; }
      .cal-week-view .cal-time-events .cal-resize-handle {
        width: 100%;
        height: 4px;
        cursor: row-resize;
        position: absolute; }
        .cal-week-view .cal-time-events .cal-resize-handle.cal-resize-handle-after-end {
          bottom: 0; }
    .cal-week-view .cal-hour-odd {
      background-color: #fafafa; }
    .cal-week-view .cal-hour-segment {
      position: relative; }
      .cal-week-view .cal-hour-segment::after {
        content: '\00a0'; }
    .cal-week-view .cal-event-container:not(.cal-draggable) {
      cursor: pointer;
      height: 120px;
      // border: 1px solid #ccc;
      border-radius: 5px;
      // padding: 5px;
      background-color: #f5f5f5; }
    .cal-week-view .cal-draggable {
      cursor: move; }
    .cal-week-view .cal-drag-over .cal-hour-segment {
      background-color: #ededed; }
    .cal-week-view mwl-calendar-week-view-hour-segment,
    .cal-week-view .cal-hour-segment {
      display: block; }
    .cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
    .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
      border-bottom: thin dashed #e1e1e1; }
    .cal-week-view .cal-time {
      font-weight: bold;
      padding-top: 5px;
      width: 70px;
      text-align: center; }
    .cal-week-view .cal-hour-segment.cal-after-hour-start .cal-time {
      display: none; }
    .cal-week-view .cal-starts-within-day .cal-event {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px; }
    .cal-week-view .cal-ends-within-day .cal-event {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      // background-color: #d0d0ffcf;
      overflow-y: auto;
     }
  
  .cal-day-view {
    /* stylelint-disable-next-line selector-type-no-unknown */ }
    .cal-day-view .cal-hour-rows {
      width: 100%;
      border: solid 1px #e1e1e1;
      overflow-x: scroll;
      position: relative; }
    .cal-day-view .cal-hour:nth-child(odd) {
      background-color: #fafafa; }
    .cal-day-view mwl-calendar-day-view-hour-segment,
    .cal-day-view .cal-hour-segment {
      display: block; }
    .cal-day-view .cal-hour-segment::after {
      content: '\00a0'; }
    .cal-day-view .cal-hour:not(:last-child) .cal-hour-segment,
    .cal-day-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
      border-bottom: thin dashed #e1e1e1; }
    .cal-day-view .cal-time {
      font-weight: bold;
      padding-top: 5px;
      width: 70px;
      text-align: center; }
    .cal-day-view .cal-hour-segment.cal-after-hour-start .cal-time {
      display: none; }
    .cal-day-view .cal-hour-segment:hover,
    .cal-day-view .cal-drag-over .cal-hour-segment {
      background-color: #ededed; }
    .cal-day-view .cal-drag-active .cal-hour-segment {
      pointer-events: none; }
    .cal-day-view .cal-event-container {
      position: absolute;
      cursor: pointer; }
      .cal-day-view .cal-event-container.resize-active {
        z-index: 1;
        pointer-events: none; }
    .cal-day-view .cal-event {
      padding: 5px;
      font-size: 12px;
      background-color: #d1e8ff;
      border: 1px solid #1e90ff;
      // color: #1e90ff;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      overflow: hidden;
      -o-text-overflow: ellipsis;
         text-overflow: ellipsis;
      white-space: nowrap;
      height: 100%; }
    .cal-day-view .cal-all-day-events > * {
      cursor: pointer; }
    .cal-day-view .cal-draggable {
      cursor: move; }
    .cal-day-view .cal-starts-within-day .cal-event {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px; }
    .cal-day-view .cal-ends-within-day .cal-event {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px; }
    .cal-day-view .cal-drag-active {
      z-index: 1; }
      .cal-day-view .cal-drag-active * {
        pointer-events: none; }
    .cal-day-view .cal-resize-handle {
      width: 100%;
      height: 4px;
      cursor: row-resize;
      position: absolute; }
      .cal-day-view .cal-resize-handle.cal-resize-handle-after-end {
        bottom: 0; }
  
  .cal-tooltip {
    display: none;}

    .cal-week-view .cal-time-events .cal-event img,  .cal-tooltip img, .patient-card-body img{
      width: 20px;
      height: 20px;
      border-radius: 50px;
    }

    .patient-card {
      background-color: #f0fbf1;
      padding: 15px;
      border: 2px solid #b1afaf;
      border-radius: 10px; /* Rounded corners */
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Light shadow */
      transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition for hover effects */
      margin-bottom: 15px; /* Space between cards */
  }
  
  /* Hover effect to add depth */
  .patient-card:hover {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
      transform: translateY(-5px); /* Slight lift on hover */
  }
  
  /* Patient name styling */
   .patient-card-header {
      font-size: 11px;
      font-weight: bold;
      color: #333; 
  }

    .calendar-custom-btn{
      margin-top: 5px !important;
      padding: 4px !important;
    }